.execution-log {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  border-top: solid 1px rgba(255, 255, 255, 0.2);

  .execution-log-container {
    height: calc(100% - 40px);
    margin: 20px;
    display: flex;
    flex-direction: column;
    overflow: auto;

    .operation-name {
      height: 50px;

       h2 {
         margin: 0;
         font-size: 20px;
         color: white;
       }
    }

    .swap-line {
      position: relative;
      height: 40px;

      .swap-line-container {
        position: absolute;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        p {
          margin: 0;
          height: 50%;
          font-size: 10px;
          font-weight: bold;
          color: goldenrod;
        }

        .line {
          height: 50%;
          width: 100%;
          border: solid 1.5px white;
          border-bottom: none;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
        }
      }
    }

    .el-array {
      height: 40px;
      width: max-content;
      display: flex;
      justify-content: space-between;
      border-radius: 5px;
      background-color: #1c1c1c;
      overflow: hidden;

      .el-array-element {
        box-sizing: border-box;
        height: 100%;
        min-width: 50px;
        width: 50px;
        display: flex;
        flex-direction: column;

        h3, p {
          width: 100%;
          margin: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: bold;
        }

        h3 {
          height: 45%;
          background-color: rgba(255, 255, 255, 0.1);
          font-size: 12px;
          color: rgba(255, 255, 255, 0.75);
        }

        p {
          height: 55%;
          font-size: 14px;
          border-top: solid 1px white;
          color: white;
          background-color: cornflowerblue;
        }
      }
    }

    .index-pointers {
      position: relative;
      height: 60px;

      .index-pointers-container {
        position: absolute;
        top: 0;
        height: 100%;
        width: 50px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        .arrow-up-icon {
          height: 50%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          .icon {font-size: 18px;}
        }

        p {
          margin: 0;
          height: 50%;
          font-size: 10px;
          font-weight: bold;
          text-align: center;
        }
      }
      .current-index {
        .icon {color: white;}
        p {color: white;}
      }
      .left-child {
        .icon {color: dodgerblue;}
        p {color: dodgerblue;}
      }
      .right-child {
        .icon {color: tomato;}
        p {color: tomato}
      }
    }

    .execution-log-info{
      height: 40px;
      width: 100%;
      display: flex;
      align-items: center;

      p {
        margin: 0;
        font-size: 12px;
        font-weight: bold;
        color: white;
      }
    }
  }
}

@keyframes executionLogSlideUp {
  from {
    display: flex;
    height: 0;
  }
}
@keyframes executionLogSlideDown {
  to {
    height: 0;
    display: none;
  }
}