.app {
  height: 100%;
  width: 100%;
  display: flex;
  background-color: #27282c;

  .sidebar {
    height: 100%;
    width: 300px;
  }

  .animation-window {
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .execution-log {
      height: 270px;
      width: 100%;
    }

    .canvas {
      flex: 1;
      width: 100%;
    }
  }
}