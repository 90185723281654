.sidebar {
  z-index: 999;
  height: 100%;
  width: 100%;
  padding: 10px 0 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  border-right: solid 1px rgba(255, 255, 255, 0.2);
  background-color: #1c1c1c;
  color: white;
  overflow: hidden;

  .main-head {
    font-size: 16px;
    color: white;
  }

  .sidebar-content {
    min-width: 275px;
    width: 275px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    .p-head {
      height: 20px;
      font-weight: bold;
      font-size: 14px;
      color: goldenrod;
    }

    .p-info {
      margin: 0 0 0 5px;
      font-weight: bold;
      font-size: 12px;
      color: teal;
    }

    .handle-array-input {
      width: 100%;
      display: flex;
      flex-direction: column;

      .p-head {margin: 0 0 10px 5px;}
      .p-info {height: 20px;}
    }

    .handle-buttons-input {
      min-height: 220px;
      height: 170px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .p-head {margin: 0 0 10px 5px;}

      .p-info {
        height: 30px;
        margin-top: 10px;
      }

      .hbi-btn {
        height: 25px;
        width: 100%;
        background-color: rgba(255, 255, 255, 0.2);
        border: none;
        border-radius: 5px;

        p {
          margin: 0;
          font-size: 12px;
          font-weight: bold;
          color: white;
        }

        &:hover {
          background-color: goldenrod;
          cursor: pointer;
          transition: 0.2s background-color ease-in-out;
        }
      }
    }

    .author-details {
      min-height: 20px;
      height: 20px;
      width: 100%;
      display: flex;

      .p-head, .p-info {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .p-head {margin: 0 0 10px 5px;}
      .p-info {color: mediumseagreen;}
    }
  }
}

@keyframes sideBarSlideRight {
  from {
    display: flex;
    width: 0;
  }
}
@keyframes sideBarSlideLeft {
  to {
    width: 0;
    display: none;
  }
}