/* Track styles */
.react-custom-scrollbars-track {
  background-color: #f5f5f5;
}

/* Thumb styles */
.react-custom-scrollbars-thumb {
  background-color: #ccc;
  border-radius: 5px;
}
