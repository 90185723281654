.canvas {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: auto;

  .row {
    border-bottom: solid 1px rgba(255, 255, 255, 0.1);
    display: flex;

    .cell {
      height: 100%;
      border-right: solid 1px rgba(255, 255, 255, 0.1);
    }
  }

  .canvas-draw {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .switch {
    z-index: 999;
    position: fixed;
    height: 35px;
    width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 1px rgba(255, 255, 255, 0.2);
    background-color: #1c1c1c;

    .icon {
      font-size: 14px;
      font-weight: bold;
      color: white;
    }

    &:hover {
      background-color: #2c2c2c;
      cursor: pointer;
    }
  }

  //switches
  .sidebar-switch {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top: none;
    border-left: none;
    left: 300px;
  }
  .toggle-sidebar {top: 0;}
  .restart-animation {top: 35px}
  .toggle-animation-status {top: 70px}

  .execution-log-switch {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom: none;
    border-right: none;
    right: 0;
    bottom: 270px;
  }

  .canvas-operation {
    position: fixed;
    top: 15px;
    right: 15px;
    height: 75px;
    width: 160px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .btn-container {
      height: 35px;
      width: 100%;
      display: flex;
      justify-content: space-between;

      .stat, button {
        box-sizing: border-box;
        height: 100%;
        width: 50px;
        display: flex;
        align-items: center;
        border-radius: 5px;
        background-color: rgba(0, 0, 0, 0.25);

        p, h3 {
          margin: 0;
          color: white;
          font-size: 10px;
          font-weight: bold;
        }
      }

      .stat {
        flex-direction: column;
        justify-content: space-around;

        p, h3{font-size: 12px;}
        h3 {color: gold;}
      }

      button {
        border: solid 1px white;

        .icon {
          color: white;
          font-size: 12px;
        }

        &:hover {background-color: rgba(255, 255, 255, 0.25);}
      }

      .vertical-btn {
        flex-direction: column;
        justify-content: center;
      }
      .horizontal-btn {
        justify-content: space-around;
      }
    }
  }
}


@keyframes toggleSideBarIconSlideRight { from {left: 0;} }
@keyframes toggleSideBarIconSlideLeft { to {left: 0;} }
@keyframes toggleExecutionLogIconSlideUp { from {bottom: 0;} }
@keyframes toggleExecutionLogIconSlideDown { to {bottom: 0;} }
@keyframes spin { to {transform: rotate(360deg);} }


@media screen and (max-width: 335px) {
  .canvas .execution-log-switch {
    border-left: none;
    right: unset;
    left: 300px;
  }
}